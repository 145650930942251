<template>
    <div>
      <personnel_bar/>  
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="ศึกษานิเทศก์"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row no-gutters>
              <v-col cols="12" md="8" class="pa-1">
                <v-simple-table class="elevation-1">
                  <thead style="background-color: wheat;">
                    <tr>
                      <th class="text-left font-weight-bold">
                        ประเภท
                      </th>
                      <th class="text-left font-weight-bold">
                        จำนวน (คน)
                      </th>
                      <th class="text-left font-weight-bold">
                        ประเภท
                      </th>
                      <th class="text-left font-weight-bold">
                        จำนวน (คน)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left">ผู้อำนวยการวิทยาลัย</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'director'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.director }}</h3></v-btn
                        >
                      </td>
                      <td class="text-left">รองผู้อำนวยการ</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'se_director'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.se_director }}</h3></v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">ข้าราชการครู</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'teacher'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.teacher }}</h3></v-btn
                        >
                      </td>
                      <td class="text-left">ครูผู้ช่วย</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'teacher_ass'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.teacher_ass }}</h3></v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">เจ้าพนักงานธุรการ</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'official_nm'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.official_nm }}</h3></v-btn
                        >
                      </td>
                      <td class="text-left">เจ้าพนักงานการเงินและบัญชี</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'official_ac'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.official_ac }}</h3></v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">นักวิเคราะห์นโยบายและแผน</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="
                            '#/admin/print_personnel_type/' + 'official_plan'
                          "
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.official_plan }}</h3></v-btn
                        >
                      </td>
                      <td class="text-left">สนับสนุน</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'official_sp'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.official_sp }}</h3></v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">เจ้าพนักงานพัสดุ</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'official_st'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.official_st }}</h3></v-btn
                        >
                      </td>
                      <td class="text-left">ผู้อำนวยการ กลุ่ม</td>
                      <td class="text-left">
                        <v-btn
                          rounded
                          color="info"
                          :href="'#/admin/print_personnel_type/' + 'director_of'"
                          target="_blank"
                        >
                          <h3>{{ personnel_sCounts.director_of }}</h3></v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                        class="text-center font-weight-bold"
                        style="background-color: wheat;"
                      >
                        รวมทั้งสิ้น
                      </td>
                      <td
                        colspan="2"
                        class="text-center font-weight-bold"
                        style="background-color: wheat;"
                      >
                        {{ personnel_sCounts.sumAll }} คน
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-col cols="12" md="4" class="text-right pa-1">
                <v-btn
                  block
                  large
                  color="primary"
                  @click.native="personnel_temporaryAdd()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                  <h3>เพิ่มรายการ</h3>
                </v-btn>
  
                <v-btn
                  block
                  large
                  color="primary"
                  @click.native="personnel_temporaryCSV()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                  <h3>นำเข้าไฟล์รายชื่อ CSV (เพิ่มและปรับปรุง)</h3>
                </v-btn>
  
                <v-btn
                  block
                  large
                  color="primary"
                  @click.native="personnel_temporaryOutofCSV()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                  <h3>นำเข้าไฟล์รายชื่อผู้ออกจากระบบ</h3>
                </v-btn>
  
                <v-btn block color="warning" large @click="UpdateJ18()"
                  >ปรับปรุงข้อมูล จ.18</v-btn
                >
  
                <v-btn block color="info" large @click="clearSearch()">
                  <v-icon>mdi-format-clear</v-icon>
                  Clear Search
                </v-btn>
  
                <v-btn block color="red" large @click="clearRetireSearch()">
                  <v-icon>mdi-delete</v-icon>
                  ปรับปรุงข้อมูลผู้เกษียณ
                </v-btn>
  
                <v-btn block color="info" large @click="repleteSearch()">
                  <v-icon>mdi-search</v-icon>
                  เลขที่ตำแหน่งซ้ำในระบบ
                </v-btn>
              </v-col>
  
              <v-col cols="12" md="8" class="text-right">
                <v-card class="pa-1">
                  <v-row no-gutters>
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                        single-line
                        hide-details
                        v-on:keyup.enter="OnEnter()"
                        filled
                        class="mb-2"
                      />
                      <v-radio-group row>
                        <v-radio
                          label="ชื่อ"
                          value="radio-1"
                          @click.native="search_name()"
                        ></v-radio>
                        <v-radio
                          label="นามสกุล"
                          value="radio-2"
                          @click.native="search_lastname()"
                        ></v-radio>
                        <v-radio
                          label="รหัสบัตรประชาชน"
                          value="radio-3"
                          @click.native="search_idcard()"
                        ></v-radio>
  
                        <v-radio
                          label="เลขที่ตำแหน่ง"
                          value="radio-4"
                          @click.native="search_idposition()"
                        ></v-radio>
  
                        <v-radio
                          label="ปีที่เกษียณ"
                          value="radio-5"
                          @click.native="search_yearretire()"
                        ></v-radio>
                        <v-radio
                          label="แสดงทั้งหมด"
                          value="radio-6"
                          @click.native="personnel_temporaryQueryAll()"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="4" class="pa-1">
                      <v-select
                        v-model="rangname_search"
                        outlined
                        :items="rang_names"
                        item-text="text"
                        item-value="value"
                        label="วิทยฐานะ"
                        @change="search_rang()"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4" class="pa-1">
                      <v-select
                        v-model="position_search"
                        outlined
                        :items="position_select"
                        item-text="text"
                        item-value="value"
                        label="ตำแหน่ง"
                        @change="search_position()"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4" class="pa-1">
                      <v-autocomplete
                        v-model="college_search"
                        outlined
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        label="สถานศึกษา"
                        @change="search_college()"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-1">
                  <v-simple-table fixed-header height="250px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            เลขที่ตำแหน่ง
                          </th>
                          <th class="text-left">
                            จำนวน
                          </th>
                          <th class="text-left">
                            ตำแหน่ง
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in personnel_temporary_idpositions"
                          :key="item.id"
                        >
                          <td><v-btn color="info" rounded @click="search_idpositionClick(item.id_position)">{{ item.id_position }}</v-btn> </td>
                          <td>{{ item.countIDposition }}</td>
                          <td>{{ item.position_name }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
  
          <v-data-table
            :headers="headers"
            :items="personnel_temporarys"
            class="elevation-1"
            :loading="loading"
            :search="search"
          >
            <template v-slot:item.brith_day="{ item }">
              {{
                item.brith_day + "/" + item.brith_month + "/" + item.brith_year
              }}
            </template>
            <template v-slot:item.appoin_day="{ item }">
              {{
                item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
              }}
            </template>
  
            <template v-slot:item.date_app_now="{ item }">
              {{
                item.date_app_now
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
            <template v-slot:item.date_app_positonnow="{ item }">
              {{
                item.date_app_positonnow
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
            <template v-slot:item.Login="{ item }">
              <v-icon color="info" @click="userLogin(item.id_card, item.p_word)">
                mdi-puzzle
              </v-icon>
            </template>
  
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="warning"
                @click.stop="personnel_temporaryEdit(item.id_card)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.dels="{ item }">
              <v-icon
                color="red"
                @click.stop="personnel_temporaryDelete(item.id_card)"
              >
                mdi-delete
              </v-icon>
            </template>
  
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </base-material-card>
  
        <!--addpersonnel_temporarydialog  -->
        <v-layout row justify-center>
          <v-dialog
            v-model="addpersonnel_temporarydialog"
            persistent
            max-width="80%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เพิ่มข้อมูลศึกษานิเทศก์"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text>
                <v-form ref="addpersonnel_temporaryform" lazy-validation>
                  <v-container grid-list-md>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <span class="font-weight-bold"
                          >1. ประเภทบุคคล/สังกัด</span
                        >
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          rounded
                          filled
                          v-model="addpersonnel_temporary.user_status"
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_sub"
                          label="Type"
                          required
                          :rules="[v => !!v || '']"
                          prepend-icon="mdi-format-list-bulleted-type"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.college_code"
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          label="วิทยาลัย"
                          @change="man_powerQuery()"
                          required
                          :rules="[v => !!v || '']"
                          prepend-icon="mdi-school"
                        >
                        </v-autocomplete>
                      </v-col>
  
                      <v-col cols="12" md="12">
                        <hr style="border: 1px solid grey;" />
                      </v-col>
                      <v-col cols="12" md="12"
                        ><span class="font-weight-bold"
                          >2. ข้อมูลพื้นฐาน</span
                        ></v-col
                      >
  
                      <v-col cols="12" md="3">
                        <v-text-field
                          @input="validateInputAdd()"
                          prepend-icon="mdi-account-key"
                          rounded
                          outlined
                          label="รหัสบัตรประชาชน"
                          v-model="addpersonnel_temporary.id_card"
                          :rules="[
                            v => (!!v && v.length == 13) || '13  ID Card',
                            validateInputcheck === 'true' ||
                              'กำหนดรหัสบัตรประชาชนไม่ถูกต้อง'
                          ]"
                          maxlength="13"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-autocomplete
                          :items="prefixs"
                          item-text="prefix_name"
                          item-value="prefix_name"
                          prepend-icon="mdi-account-box"
                          rounded
                          outlined
                          label="คำนำหน้าชื่อ"
                          v-model="addpersonnel_temporary.title_s"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          prepend-icon="mdi-account-box"
                          rounded
                          outlined
                          label="ชื่อ"
                          v-model="addpersonnel_temporary.frist_name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          prepend-icon="mdi-account-box"
                          rounded
                          outlined
                          label="นามสกุล"
                          v-model="addpersonnel_temporary.last_name"
                        ></v-text-field>
                      </v-col>
  
                      <v-col cols="12" md="4">
                        <v-select
                          :items="days"
                          item-text="text"
                          item-value="value"
                          prepend-icon="mdi-calendar"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.brith_day"
                          label="วันที่เกิด : 8"
                        ></v-select>
                      </v-col>
  
                      <v-col cols="12" md="4">
                        <v-select
                          :items="mounts"
                          item-text="text"
                          item-value="value"
                          prepend-icon="mdi-calendar"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.brith_month"
                          label="เดือนเกิด : 8"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="years"
                          item-text="text"
                          item-value="value"
                          prepend-icon="mdi-calendar"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.brith_year"
                          label="ปีเกิด : 2531"
                          type="number"
                          maxlength="4"
                        ></v-autocomplete>
                      </v-col>
  
                      <v-col cols="12" md="6">
                        <v-text-field
                          prepend-icon="mdi-cellphone"
                          rounded
                          outlined
                          label="เบอร์โทร"
                          v-model="addpersonnel_temporary.tel_p"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          prepend-icon="mdi-email"
                          rounded
                          outlined
                          label="E-mail"
                          v-model="addpersonnel_temporary.e_mail"
                        ></v-text-field>
                      </v-col>
  
                      <v-col cols="12" md="12">
                        <hr style="border: 1px solid grey;" />
                      </v-col>
                      <v-col cols="12" md="12"
                        ><span class="font-weight-bold">3. ตำแหน่ง</span></v-col
                      >
  
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          prepend-icon="mdi-account-settings"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.position_name"
                          :items="position_select"
                          item-text="text"
                          item-value="text"
                          label="ตำแหน่ง"
                        ></v-autocomplete>
                      </v-col>
  
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          prepend-icon="mdi-account-settings"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.user_status"
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_sub"
                          label="สถานะ"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          prepend-icon="mdi-account-settings"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.id_position"
                          :items="man_powers"
                          item-text="id_position"
                          item-value="id_position"
                          label="เลขที่ตำแหน่ง"
                          ><template v-slot:item="{ item }">
                            <div>
                              <div class="font-weight-bold">
                                {{ item.id_position }}
                              </div>
                              <div>{{ item.positions }} : {{ item.id_card }}</div>
                            </div>
                          </template></v-autocomplete
                        >
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          prepend-icon="mdi-account-settings"
                          outlined
                          rounded
                          :items="rang_names"
                          item-text="text"
                          item-value="value"
                          label="วิทยฐานะ"
                          v-model="addpersonnel_temporary.rang_name"
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-col>
  
                      <v-col cols="12" md="6">
                        <v-select
                          prepend-icon="mdi-account-settings"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.rang_level"
                          :items="rang_levels"
                          item-text="text"
                          item-value="value"
                          label="ระดับ"
                        ></v-select>
                      </v-col>
  
                      <v-col cols="12" md="12">
                        <hr style="border: 1px solid grey;" />
                      </v-col>
                      <v-col cols="12" md="12"
                        ><span class="font-weight-bold"
                          >4. วุฒิการศึกษา</span
                        ></v-col
                      >
  
                      <v-col cols="12" md="6">
                        <v-text-field
                          prepend-icon="mdi-account-settings"
                          rounded
                          outlined
                          label="คุณวิฒิ"
                          v-model="addpersonnel_temporary.ed_abb"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          prepend-icon="mdi-account-settings"
                          rounded
                          outlined
                          label="สาขาวิชา"
                          v-model="addpersonnel_temporary.ed_name"
                        ></v-text-field>
                      </v-col>
  
                      <v-col cols="12" md="12">
                        <hr style="border: 1px solid grey;" />
                      </v-col>
                      <v-col cols="12" md="12"
                        ><span class="font-weight-bold">5. การบรรจุ</span></v-col
                      >
  
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          prepend-icon="mdi-account-settings"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.type_personnel"
                          :items="type_personnelApp"
                          item-text="text"
                          item-value="value"
                          label="ประเภทการบรรจุ"
                        ></v-autocomplete>
                      </v-col>
  
                      <v-col cols="12" md="4" class="pa-5">
                        <v-checkbox
                          v-model="addpersonnel_temporary.rang_condition"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                          label="กลุ่ม จชต. ชายแดนภาคใต้"
                        ></v-checkbox>
                      </v-col>
  
                      <v-col cols="12" md="4">
                        <v-text-field
                          prepend-icon="mdi-content-duplicate"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.order_app_now"
                          label="คำสั่งที่บรรจุ"
                        ></v-text-field>
                      </v-col>
  
                      <!-- บรรจะ -->
                      <v-col cols="12" md="4">
                        <v-select
                          :items="days"
                          item-text="text"
                          item-value="value"
                          prepend-icon="mdi-calendar-clock"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.appoin_day"
                          label="วันที่บรรจุ"
                        ></v-select>
                      </v-col>
  
                      <v-col cols="12" md="4">
                        <v-select
                          :items="mounts"
                          item-text="text"
                          item-value="value"
                          prepend-icon="mdi-calendar-clock"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.appoin_month"
                          label="เดือนบรรจุ"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="years"
                          item-text="text"
                          item-value="value"
                          prepend-icon="mdi-calendar-clock"
                          rounded
                          outlined
                          v-model="addpersonnel_temporary.appoin_year"
                          label="ปีบรรจุ"
                        ></v-autocomplete>
                      </v-col>
  
                      <v-col cols="12" md="12">
                        <hr style="border: 1px solid grey;" />
                      </v-col>
                      <v-col cols="12" md="12"
                        ><span class="font-weight-bold"
                          >6. การปฏิบัติงาน ณ ปัจจุบัน</span
                        ></v-col
                      >
  
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menu5"
                          v-model="menu5"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              prepend-icon="mdi-calendar-clock"
                              rounded
                              outlined
                              v-model="addpersonnel_temporary.date_app_now"
                              label="วันเดือนปีที่เริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              locale="th"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="addpersonnel_temporary.date_app_now"
                            no-title
                            scrollable
                            locale="th"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu5 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu5.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
  
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menu6"
                          v-model="menu6"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              prepend-icon="mdi-calendar-clock"
                              rounded
                              outlined
                              v-model="addpersonnel_temporary.date_app_positonnow"
                              label="วันเดือนปีที่เริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              locale="th"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="addpersonnel_temporary.date_app_positonnow"
                            no-title
                            scrollable
                            locale="th"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu6 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu6.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>* จำเป็น</small>
                  <span>ปีเกษียณ :{{ retrire_year }}</span>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  large
                  @click.stop="addpersonnel_temporarydialog = false"
                  rounded
                  ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="success"
                  @click.stop="addpersonnel_temporarySubmit()"
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model csvpersonnel_temporarydialog -->
        <v-layout>
          <v-dialog
            v-model="csvpersonnel_temporarydialog"
            persistent
            max-width="50%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="green"
                icon="mdi-content-paste"
                title="นำเข้าข้อมูลไฟล์ CSV"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text>
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="csvpersonnel_temporarydialogform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-row>
                          <v-col cols="12">
                            <v-btn
                              block
                              large
                              color="info"
                              to="/college/personnel_form_import/"
                              target="_blank"
                            >
                              <v-icon>mdi-exit-to-app</v-icon
                              >ส่งออกไฟล์บันทึกข้อมูล</v-btn
                            >
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV ข้อมูลบุคคล สอศ"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>
  
                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                            <v-btn
                              block
                              large
                              color="green"
                              @click.stop="csvpersonnel_temporarySubmit()"
                            >
                              <v-icon>mdi-book-plus</v-icon>
                              นำเข้า</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    large
                    color="grey"
                    @click.stop="csvpersonnel_temporarydialog = false"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model csvpersonnel_temporaryOutofdialog -->
        <v-layout>
          <v-dialog
            v-model="csvpersonnel_temporaryOutofdialog"
            persistent
            max-width="50%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="green"
                icon="mdi-content-paste"
                title="นำเข้าข้อมูลไฟล์ ผู้ออกจากราชการ ลาออก เกษียณ ถึงแก่กรรม CSV"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text>
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="csvpersonnel_temporaryOutofdialogform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-row>
                          <v-col cols="12">
                            <v-btn
                              block
                              large
                              color="info"
                              to="/admin/personneloutof_form_import/"
                              target="_blank"
                            >
                              <v-icon>mdi-exit-to-app</v-icon
                              >ส่งออกไฟล์บันทึกข้อมูล</v-btn
                            >
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV ข้อมูลบุคคล สอศ"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>
  
                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                            <v-btn
                              block
                              large
                              color="green"
                              @click.stop="csvpersonnel_temporaryOutofSubmit()"
                            >
                              <v-icon>mdi-book-plus</v-icon>
                              นำเข้า</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    large
                    color="grey"
                    @click.stop="csvpersonnel_temporaryOutofdialog = false"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model deletepersonnel_temporarydialog -->
        <v-layout>
          <v-dialog
            v-model="deletepersonnel_temporarydialog"
            persistent
            max-width="40%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบข้อมูลผู้ใช้"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="deletepersonnel_temporaryform" lazy-validation>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบข้อมูลผู้ใช้
                            {{ editpersonnel_temporary.college_code }}
                            <span v-if="editpersonnel_temporary.college_name">{{
                              editpersonnel_temporary.college_name
                            }}</span>
                            <div>
                              {{ editpersonnel_temporary.title_s
                              }}{{ editpersonnel_temporary.frist_name }}
                              {{ editpersonnel_temporary.last_name }}
                            </div>
                            <div>
                              {{ editpersonnel_temporary.position_name }} :
                              {{ editpersonnel_temporary.id_position }}
                            </div>
                          </v-flex>
                          <v-flex>
                            <v-select
                              v-model="
                                del_personnel_temporary_del.ptdel_status_del
                              "
                              label="ประเภทการลบ"
                              :items="delPersonneltype"
                              item-text="text"
                              item-value="value"
                              outlined
                              rounded
                              :rules="[v => !!v || '']"
                            ></v-select>
                          </v-flex>
                          <v-flex>
                            <v-menu
                              ref="menu8"
                              v-model="menu8"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  prepend-icon="mdi-calendar-clock"
                                  rounded
                                  outlined
                                  v-model="
                                    del_personnel_temporary_del.ptdel_datetime_outof
                                  "
                                  label="ออกโดยมีผลวันที่"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  locale="th"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  del_personnel_temporary_del.ptdel_datetime_outof
                                "
                                no-title
                                scrollable
                                locale="th"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="menu8 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu8.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-flex>
  
                          <v-text-field
                            filled
                            rounded
                            type="password"
                            label="รหัสผ่าน สำหรับลบข้อมูล"
                            :rules="[v => v === '0612698358']"
                          >
                          </v-text-field>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="deletepersonnel_temporarydialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deletepersonnel_temporarySubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model editpersonnel_temporarydialog -->
        <v-layout row justify-center>
          <v-dialog
            v-model="editpersonnel_temporarydialog"
            persistent
            max-width="80%"
          >
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขข้อมูลผู้ใช้งานระบบ"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editpersonnel_temporaryform" lazy-validation>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <span class="font-weight-bold">1. ประเภทบุคคล/สังกัด</span>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        rounded
                        filled
                        v-model="editpersonnel_temporary.user_status"
                        :items="userstatus"
                        item-text="user_status_name"
                        item-value="user_status_sub"
                        label="Type"
                        required
                        :rules="[v => !!v || '']"
                        prepend-icon="mdi-format-list-bulleted-type"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.college_code"
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        label="วิทยาลัย"
                        @change="man_powerQuery()"
                        required
                        :rules="[v => !!v || '']"
                        prepend-icon="mdi-school"
                      >
                      </v-autocomplete>
                    </v-col>
  
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >2. ข้อมูลพื้นฐาน</span
                      ></v-col
                    >
  
                    <v-col cols="12" md="3">
                      <v-text-field
                        readonly
                        @input="validateInput()"
                        prepend-icon="mdi-account-key"
                        rounded
                        outlined
                        label="รหัสบัตรประชาชน"
                        v-model="editpersonnel_temporary.id_card"
                        :rules="[
                          v => (!!v && v.length == 13) || '13  ID Card',
                          validateInputcheck === 'true' ||
                            'กำหนดรหัสบัตรประชาชนไม่ถูกต้อง'
                        ]"
                        maxlength="13"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-autocomplete
                        :items="prefixs"
                        item-text="prefix_name"
                        item-value="prefix_name"
                        prepend-icon="mdi-account-box"
                        rounded
                        outlined
                        label="คำนำหน้าชื่อ"
                        v-model="editpersonnel_temporary.title_s"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-account-box"
                        rounded
                        outlined
                        label="ชื่อ"
                        v-model="editpersonnel_temporary.frist_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        prepend-icon="mdi-account-box"
                        rounded
                        outlined
                        label="นามสกุล"
                        v-model="editpersonnel_temporary.last_name"
                      ></v-text-field>
                    </v-col>
  
                    <v-col cols="12" md="4">
                      <v-select
                        :items="days"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.brith_day"
                        label="วันที่เกิด : 8"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="4">
                      <v-select
                        :items="mounts"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.brith_month"
                        label="เดือนเกิด : 8"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="years"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.brith_year"
                        label="ปีเกิด : 2531"
                        type="number"
                        maxlength="4"
                      ></v-autocomplete>
                    </v-col>
  
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-cellphone"
                        rounded
                        outlined
                        label="เบอร์โทร"
                        v-model="editpersonnel_temporary.tel_p"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-email"
                        rounded
                        outlined
                        label="E-mail"
                        v-model="editpersonnel_temporary.e_mail"
                      ></v-text-field>
                    </v-col>
  
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold">3. ตำแหน่ง</span></v-col
                    >
  
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.position_name"
                        :items="position_select"
                        item-text="text"
                        item-value="text"
                        label="ตำแหน่ง"
                      ></v-autocomplete>
                    </v-col>
  
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.user_status"
                        :items="userstatus"
                        item-text="user_status_name"
                        item-value="user_status_sub"
                        label="สถานะ"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.id_position"
                        :items="man_powers"
                        item-text="id_position"
                        item-value="id_position"
                        label="เลขที่ตำแหน่ง"
                        ><template v-slot:item="{ item }">
                          <div>
                            <div class="font-weight-bold">
                              {{ item.id_position }}
                            </div>
                            <div>{{ item.positions }} : {{ item.id_card }}</div>
                          </div>
                        </template></v-autocomplete
                      >
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        outlined
                        rounded
                        :items="rang_names"
                        item-text="text"
                        item-value="value"
                        label="วิทยฐานะ"
                        v-model="editpersonnel_temporary.rang_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-col>
  
                    <v-col cols="12" md="4">
                      <v-select
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.rang_level"
                        :items="rang_levels"
                        item-text="text"
                        item-value="value"
                        label="ระดับ"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="4">
                      <v-text-field
                        type="number"
                        prepend-icon="mdi-currency-btc"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.salary_s"
                        label="เงินเดือน"
                      ></v-text-field>
                    </v-col>
  
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >4. วุฒิการศึกษา</span
                      ></v-col
                    >
  
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        label="คุณวิฒิ"
                        v-model="editpersonnel_temporary.ed_abb"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        label="สาขาวิชา"
                        v-model="editpersonnel_temporary.ed_name"
                      ></v-text-field>
                    </v-col>
  
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold">5. การบรรจุ</span></v-col
                    >
  
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.type_personnel"
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        label="ประเภทการบรรจุ"
                      ></v-autocomplete>
                    </v-col>
  
                    <v-col cols="12" md="4" class="pa-5">
                      <v-checkbox
                        v-model="editpersonnel_temporary.rang_condition"
                        value="1"
                        hide-details
                        class="shrink mr-2 mt-0"
                        label="กลุ่ม จชต. ชายแดนภาคใต้"
                      ></v-checkbox>
                    </v-col>
  
                    <v-col cols="12" md="4">
                      <v-text-field
                        prepend-icon="mdi-content-duplicate"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.order_app_now"
                        label="คำสั่งที่บรรจุ"
                      ></v-text-field>
                    </v-col>
  
                    <!-- บรรจะ -->
                    <v-col cols="12" md="4">
                      <v-select
                        :items="days"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar-clock"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.appoin_day"
                        label="วันที่บรรจุ"
                      ></v-select>
                    </v-col>
  
                    <v-col cols="12" md="4">
                      <v-select
                        :items="mounts"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar-clock"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.appoin_month"
                        label="เดือนบรรจุ"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="years"
                        item-text="text"
                        item-value="value"
                        prepend-icon="mdi-calendar-clock"
                        rounded
                        outlined
                        v-model="editpersonnel_temporary.appoin_year"
                        label="ปีบรรจุ"
                      ></v-autocomplete>
                    </v-col>
  
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >6. การปฏิบัติงาน ณ ปัจจุบัน</span
                      ></v-col
                    >
  
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar-clock"
                            rounded
                            outlined
                            v-model="editpersonnel_temporary.date_app_now"
                            label="วันเดือนปีที่เริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editpersonnel_temporary.date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu5 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu5.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
  
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="menu6"
                        v-model="menu6"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar-clock"
                            rounded
                            outlined
                            v-model="editpersonnel_temporary.date_app_positonnow"
                            label="วันเดือนปีที่เริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editpersonnel_temporary.date_app_positonnow"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu6 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu6.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
  
                  <small
                    >* *ไม่สามาถแก้ไขเลขบัตรประจำประชาชนได้
                    หากตรวจสอบแล้วพบข้อผิดพลาดในรหัสบัตรประชาชน ให้ติดต่อ
                    Admin</small
                  >
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editpersonnel_temporarydialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editpersonnel_temporarySubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>
  
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import personnel_bar from "../../components/admin/personnel_bar.vue";
  export default {
    components: { personnel_bar },
    data() {
      return {
        loading: false,
        ApiKey: "HRvec2021",
        valid: true,
        addpersonnel_temporarydialog: false,
        editpersonnel_temporarydialog: false,
        deletepersonnel_temporarydialog: false,
        csvpersonnel_temporarydialog: false,
        csvpersonnel_temporaryOutofdialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        personnel_temporarys: [],
        addpersonnel_temporary: {},
        editpersonnel_temporary: {},
        search: "",
        pagination: {},
        headers: [
          {
            text: "Login",
            align: "center",
            value: "Login",
            icon: "mdi-puzzle"
          },
  
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "dels",
            icon: "mdi-file-document-edit"
          },
          {
            text: "วิทยาลัย",
            align: "left",
            value: "college_name"
          },
          { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
          { text: "คำนำ", align: "center", value: "title_s" },
          { text: "ชื่อ", align: "center", value: "frist_name" },
          { text: "สกุล", align: "center", value: "last_name" },
          { text: "ตำแหน่ง", align: "center", value: "position_name" },
          { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
          { text: "วิทยฐานะ", align: "center", value: "rang_name" },
          { text: "ระดับ", align: "center", value: "rang_level" },
          { text: "วันเดือนปีเกิด", align: "center", value: "brith_day" },
          { text: "วันเดือนปีบรรจุ", align: "center", value: "appoin_day" },
          { text: "คำสั่งที่", align: "center", value: "order_app_now" },
          {
            text: "วันที่ปฏิบัติหน้าที่ ณ สถานศึกษาปัจจุบัน",
            align: "center",
            value: "date_app_now"
          },
          {
            text: "วันที่ดำรงตำแหน่งปัจจุบัน",
            align: "center",
            value: "date_app_positonnow"
          },
  
          { text: "เกษียณ", align: "center", value: "retrire_year" }
        ],
        delPersonneltype: [
          { text: "ลาออก", value: "resign" },
          { text: "ให้ออก", value: "discard" },
          { text: "ไล่ออก", value: "dismiss" },
          { text: "โอนย้าย", value: "transfer" },
          { text: "เกษียณ", value: "retire" },
          { text: "เสียชีวิต", value: "died" },
          { text: "สละสิทธิ์ไม่รายงานตัว", value: "disclaim" }
        ],
        position_select: [
          { text: "ผู้อำนวยการวิทยาลัย", value: "ผู้อำนวยการวิทยาลัย" },
          { text: "รองผู้อำนวยการวิทยาลัย", value: "รองผู้อำนวยการวิทยาลัย" },
          { text: "ครู", value: "ครู" },
          { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
          { text: "ศึกษานิเทศก์", value: "ศึกษานิเทศก์" },
          { text: "เจ้าพนักงานธุรการ", value: "เจ้าพนักงานธุรการ" },
          {
            text: "เจ้าพนักงานการเงินและบัญชี",
            value: "เจ้าพนักงานการเงินและบัญชี"
          },
          { text: "นักวิเคราะห์นโยบายและแผน", value: "นักวิเคราะห์นโยบายและแผน" },
          { text: "เจ้าพนักงานพัสดุ", value: "เจ้าพนักงานพัสดุ" }
        ],
  
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
  
        college: {},
        provinces: [],
        prefectures: [],
        userstatus: [],
        man_powers: [],
        colleges: [],
        personnel_temporarystatus: [],
        regions: [],
        region_ena: true,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menu: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu6: false,
        menu7: false,
        menu8: false,
        data_syslog: {},
        college_search: {},
        position_search: {},
        rangname_search: {},
        upcsv_file: {},
        file2: null,
        type_personnelApp: [
          { text: "ครูพัฒนาท้องถิ่น", value: "local_dev" },
          { text: "ครูโอนย้าย", value: "transfer_position" },
          { text: "คัดเลือก(ภายใน)", value: "select" },
          { text: "แข่งขัน(ภายนอก)", value: "recruit" }
        ],
  
        rang_names: [
          
          { text: "ต้น", value: "ต้น" },
          { text: "ปฏิบัติงาน", value: "ปฏิบัติงาน" },
          { text: "ปฏิบัติการ", value: "ปฏิบัติการ" },
          { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
          { text: "ครู", value: "ครู" },
          { text: "ชำนาญงาน", value: "ชำนาญงาน" },
          { text: "ชำนาญการ", value: "ชำนาญการ" },
          { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
          { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
          { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
        ],
        rang_levels: [
          
          { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" }
        ],
        validateInputcheck: [],
        days: [
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" },
          { text: "6", value: "6" },
          { text: "7", value: "7" },
          { text: "8", value: "8" },
          { text: "9", value: "9" },
          { text: "10", value: "10" },
          { text: "11", value: "11" },
          { text: "12", value: "12" },
          { text: "13", value: "13" },
          { text: "14", value: "14" },
          { text: "15", value: "15" },
          { text: "16", value: "16" },
          { text: "17", value: "17" },
          { text: "18", value: "18" },
          { text: "19", value: "19" },
          { text: "20", value: "20" },
          { text: "21", value: "21" },
          { text: "22", value: "22" },
          { text: "23", value: "23" },
          { text: "24", value: "24" },
          { text: "25", value: "25" },
          { text: "26", value: "26" },
          { text: "27", value: "27" },
          { text: "28", value: "28" },
          { text: "29", value: "29" },
          { text: "30", value: "30" },
          { text: "31", value: "31" }
        ],
        mounts: [
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" },
          { text: "6", value: "6" },
          { text: "7", value: "7" },
          { text: "8", value: "8" },
          { text: "9", value: "9" },
          { text: "10", value: "10" },
          { text: "11", value: "11" },
          { text: "12", value: "12" }
        ],
        years: [
          { text: "2500", value: "2500" },
          { text: "2501", value: "2501" },
          { text: "2502", value: "2502" },
          { text: "2503", value: "2503" },
          { text: "2504", value: "2504" },
          { text: "2505", value: "2505" },
          { text: "2506", value: "2506" },
          { text: "2507", value: "2507" },
          { text: "2508", value: "2508" },
          { text: "2509", value: "2509" },
          { text: "2510", value: "2510" },
          { text: "2511", value: "2511" },
          { text: "2512", value: "2512" },
          { text: "2513", value: "2513" },
          { text: "2514", value: "2514" },
          { text: "2515", value: "2515" },
          { text: "2516", value: "2516" },
          { text: "2517", value: "2517" },
          { text: "2518", value: "2518" },
          { text: "2519", value: "2519" },
          { text: "2520", value: "2520" },
          { text: "2521", value: "2521" },
          { text: "2522", value: "2522" },
          { text: "2523", value: "2523" },
          { text: "2524", value: "2524" },
          { text: "2525", value: "2525" },
          { text: "2526", value: "2526" },
          { text: "2527", value: "2527" },
          { text: "2528", value: "2528" },
          { text: "2529", value: "2529" },
          { text: "2530", value: "2530" },
          { text: "2531", value: "2531" },
          { text: "2532", value: "2532" },
          { text: "2533", value: "2533" },
          { text: "2534", value: "2534" },
          { text: "2535", value: "2535" },
          { text: "2536", value: "2536" },
          { text: "2537", value: "2537" },
          { text: "2538", value: "2538" },
          { text: "2539", value: "2539" },
          { text: "2540", value: "2540" },
          { text: "2541", value: "2541" },
          { text: "2542", value: "2542" },
          { text: "2543", value: "2543" },
          { text: "2544", value: "2544" },
          { text: "2545", value: "2545" },
          { text: "2546", value: "2546" },
          { text: "2547", value: "2547" },
          { text: "2548", value: "2548" },
          { text: "2549", value: "2549" },
          { text: "2550", value: "2550" },
          { text: "2551", value: "2551" },
          { text: "2552", value: "2552" },
          { text: "2553", value: "2553" },
          { text: "2554", value: "2554" },
          { text: "2555", value: "2555" },
          { text: "2556", value: "2556" },
          { text: "2557", value: "2557" },
          { text: "2558", value: "2558" },
          { text: "2559", value: "2559" },
          { text: "2560", value: "2560" },
  
          { text: "2561", value: "2561" },
          { text: "2562", value: "2562" },
          { text: "2563", value: "2563" },
          { text: "2564", value: "2564" },
          { text: "2565", value: "2565" },
          { text: "2566", value: "2566" },
          { text: "2567", value: "2567" },
          { text: "2568", value: "2568" },
          { text: "2569", value: "2569" },
          { text: "2570", value: "2570" }
        ],
        prefixs: [],
        del_personnel_temporary_del: {},
        del_personnel_temporary_retire: {},
        ins_personnel_temporary_del_retire: {},
        personnel_sCounts: [],
        updateJ18s: {},
        personnel_temporary_idpositions: [],
      
      };
    },
  
    async mounted() {      
      await this.sweetAlertLoading();
      await this.personnel_temporaryQueryAll();
      let result;
      result = await this.$http.post("collegetype.php", {
        ApiKey: this.ApiKey
      });
      this.collegetypes = result.data;
      result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey
      });
  
      this.colleges = result.data;
      result = await this.$http.post("province.php", {
        ApiKey: this.ApiKey
      });
      this.provinces = result.data;
  
      result = await this.$http.post("region.php", {
        ApiKey: this.ApiKey
      });
      this.regions = result.data;
  
      let user_status_result;
      user_status_result = await this.$http.post("user_status.php", {
        ApiKey: this.ApiKey
      });
      this.userstatus = user_status_result.data;
  
      let prefix = await this.$http.post("prefix.php", { ApiKey: this.ApiKey });
      this.prefixs = prefix.data;
      await this.personnel_temporaryCount();
      Swal.close();
    },
  
    methods: {
      async repleteSearch() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          repetitive: "ok"
        });
        this.personnel_temporary_idpositions = result.data;
        Swal.close();
      },
      async personnel_temporaryCount() {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          countTypePerson: "Ok"
        });
        this.personnel_sCounts = result.data;
        let data = this.personnel_sCounts;
        let sum = 0;
        for (var el in data) {
          if (data.hasOwnProperty(el)) {
            sum += parseFloat(data[el]);
          }
        }
        this.personnel_sCounts.sumAll = sum;
      },
      async clearRetireSearch() {
        let yearRetire = this.search;
        Swal.fire({
          title: "ต้องการลบผู้เกษียณอายุราชการปี",
          text: yearRetire,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก"
        }).then(async result => {
          if (result.isConfirmed) {
            this.ins_personnel_temporary_del_retire.ApiKey = this.ApiKey;
            this.ins_personnel_temporary_del_retire.retrire_year = this.search;
            let resultins = await this.$http.post(
              "personnel_temporary.retire.update.php",
              this.ins_personnel_temporary_del_retire
            );
            
            if (resultins.data.status == true) {
              this.del_personnel_temporary_retire.ApiKey = this.ApiKey;
              this.del_personnel_temporary_retire.retrire_year = this.search;
              let resultDel = await this.$http.post(
                "personnel_temporary.retire.delete.php",
                this.del_personnel_temporary_retire
              );
              
  
              if (resultDel.data.status == true) {
                Swal.fire({
                  title: "ดำเนินการข้อมูลเรียบร้อย",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "ผิดพลาด",
                  showConfirmButton: false,
                  timer: 1500
                });
              }
            } else {
              Swal.fire({
                icon: "warning",
                title: "ผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        });
      },
      async clearSearch() {
        this.college_search = "";
        this.position_search = "";
        this.rangname_search = "";
      },
  
      async userLogin(id_card, p_word) {
        let result = await this.$http.post("login.php?crud=userByAdmin", {
          id_card: id_card,
          p_word: p_word
        });
        if (result.data.user_status) {
          let user = result.data;
          user.system_lock = false;
          sessionStorage.setItem("user", JSON.stringify(user));
          if (user.user_status == "teacher") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/user");
          } else if (user.user_status == "se_director") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/user");
          } else if (user.user_status == "director") {
            this.dialog = true;
            sessionStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/user");
          } else {
            sessionStorage.clear();
            this.dialog = true;
            this.dialog_msg = "ชื่อผู้ใช้ หรือรหัสผ่าน ไม่ถูกต้อง";
          }
        }
      },
  
      async sweetAlertLoading() {
        Swal.fire({
          title: "Please Wait !",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            
          },
          onBeforeOpen: () => {
            Swal.showLoading();
            
          },
          onAfterClose: () => {
            Swal.hideLoading();
            
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      },
  
      async search_name() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          frist_name: this.search
        });
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async search_lastname() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          last_name: this.search
        });
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async search_idcard() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_cards: this.search
        });
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async search_idposition() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_position: this.search
        });
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async search_idpositionClick(id_position) {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_position: id_position
        });
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
  
      async search_college() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            college_code: this.college_search
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async search_position() {
        await this.sweetAlertLoading();
        await this.search_positionQuery();
        Swal.close();
      },
      async search_positionQuery() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            user_status: this.position_search
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async search_rang() {
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            rang_name: this.rangname_search
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
      },
      async search_yearretire() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            retrire_year: this.search
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async OnEnter() {
        await this.sweetAlertLoading();
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          frist_name: this.search
        });
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async man_powerQuery() {
        let man_power_result;
        man_power_result = await this.$http.post("jor18.php", {
          ApiKey: this.ApiKey,
          j18college_code: this.addpersonnel_temporary.college_code
        });
        this.man_powers = man_power_result.data;
      },
  
      async personnel_temporaryQueryAll() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            user_status:'supervision'
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async searchWait() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            status_appove: "wait"
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async personnel_temporaryAdd() {
        this.addpersonnel_temporary = {};
        this.addpersonnel_temporarydialog = true;
      },
  
      async personnel_temporaryCSV() {
        this.csvpersonnel_temporarydialog = true;
      },
  
      async personnel_temporaryOutofCSV() {
        this.csvpersonnel_temporaryOutofdialog = true;
      },
  
      async addpersonnel_temporarySubmit() {
        if (this.$refs.addpersonnel_temporaryform.validate()) {
          this.addpersonnel_temporary.ApiKey = this.ApiKey;
          this.addpersonnel_temporary.p_word =
            this.addpersonnel_temporary.brith_day +
            "/" +
            this.addpersonnel_temporary.brith_month +
            "/" +
            this.addpersonnel_temporary.brith_year;
          let result = await this.$http.post(
            "personnel_temporary.insert.php",
            this.addpersonnel_temporary
          );
          
          if (result.data.status == true) {
            this.personnel_temporary = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "insert";
            this.data_syslog.page_log = "personnel_temporary";
            this.data_syslog.table_log = "personnel_temporary";
            this.data_syslog.detail_log = this.personnel_temporary.id_card;
            this.data_syslog.date_times = this.date_today_log;
            this.personnel_temporaryQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "บันทึกข้อมูลผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporaryQueryAll();
          }
          this.addpersonnel_temporarydialog = false;
        }
      },
      async personnel_temporaryEdit(id_card) {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: id_card
        });
        this.editpersonnel_temporary = result.data;
        this.editpersonnel_temporary.p_word = "";
        let man_power_result;
        man_power_result = await this.$http.post("jor18.php", {
          ApiKey: this.ApiKey,
          j18college_code: this.editpersonnel_temporary.college_code
        });
        this.man_powers = man_power_result.data;
        this.validateInput();
        this.editpersonnel_temporarydialog = true;
      },
  
      async editpersonnel_temporarySubmit() {
        if (this.$refs.editpersonnel_temporaryform.validate()) {
          this.editpersonnel_temporary.ApiKey = this.ApiKey;
          if (this.editpersonnel_temporary.p_word == "")
            delete this.editpersonnel_temporary.p_word;
  
          if (this.editpersonnel_temporary.rang_condition == null)
            this.editpersonnel_temporary.rang_condition = "0";
  
  
          let result = await this.$http.post(
            "personnel_temporary.update.admin.php",
            this.editpersonnel_temporary
          );
          
          if (result.data.status == true) {
            this.personnel_temporary = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporaryQueryAll();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "update";
            this.data_syslog.page_log = "personnel_temporary";
            this.data_syslog.table_log = "personnel_temporary";
            this.data_syslog.detail_log = this.personnel_temporary.id_card;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.editpersonnel_temporarydialog = false;
        }
      },
      async personnel_temporaryDelete(id_card) {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: id_card
        });
        this.editpersonnel_temporary = result.data;
        this.deletepersonnel_temporarydialog = true;
      },
  
      async deletepersonnel_temporarySubmit() {
        if (this.$refs.deletepersonnel_temporaryform.validate()) {
          this.editpersonnel_temporary.ApiKey = this.ApiKey;
  
          this.del_personnel_temporary_del.ApiKey = this.ApiKey;
          this.del_personnel_temporary_del.ptdel_college_code = this.editpersonnel_temporary.college_code;
          this.del_personnel_temporary_del.ptdel_id_card = this.editpersonnel_temporary.id_card;
          this.del_personnel_temporary_del.ptdel_title_s = this.editpersonnel_temporary.title_s;
          this.del_personnel_temporary_del.ptdel_frist_name = this.editpersonnel_temporary.frist_name;
          this.del_personnel_temporary_del.ptdel_last_name = this.editpersonnel_temporary.last_name;
          this.del_personnel_temporary_del.ptdel_position_name = this.editpersonnel_temporary.position_name;
          this.del_personnel_temporary_del.ptdel_id_position = this.editpersonnel_temporary.id_position;
          this.del_personnel_temporary_del.ptdel_salary_s = this.editpersonnel_temporary.salary_s;
          this.del_personnel_temporary_del.ptdel_rang_name = this.editpersonnel_temporary.rang_name;
          this.del_personnel_temporary_del.ptdel_rang_level = this.editpersonnel_temporary.rang_level;
          this.del_personnel_temporary_del.ptdel_ed_abb = this.editpersonnel_temporary.ed_abb;
          this.del_personnel_temporary_del.ptdel_ed_name = this.editpersonnel_temporary.ed_name;
          this.del_personnel_temporary_del.ptdel_brith_day = this.editpersonnel_temporary.brith_day;
          this.del_personnel_temporary_del.ptdel_brith_month = this.editpersonnel_temporary.brith_month;
          this.del_personnel_temporary_del.ptdel_brith_year = this.editpersonnel_temporary.brith_year;
          this.del_personnel_temporary_del.ptdel_appoin_day = this.editpersonnel_temporary.appoin_day;
          this.del_personnel_temporary_del.ptdel_appoin_month = this.editpersonnel_temporary.appoin_month;
          this.del_personnel_temporary_del.ptdel_appoin_year = this.editpersonnel_temporary.appoin_year;
          this.del_personnel_temporary_del.ptdel_retire_year = this.editpersonnel_temporary.retrire_year;
          this.del_personnel_temporary_del.ptdel_datetime = this.date_today_log;
  
          let datadelType = this.del_personnel_temporary_del.ptdel_status_del;
          let j18_backgrounds = "";
          if (datadelType == "resign") {
            j18_backgrounds = "ลาออก";
          } else if (datadelType == "discard") {
            j18_backgrounds = "ให้ออก";
          } else if (datadelType == "dismiss") {
            j18_backgrounds = "ไล่ออก";
          } else if (datadelType == "transfer") {
            j18_backgrounds = "โอนย้าย";
          } else if (datadelType == "retire") {
            j18_backgrounds = "เกษียณ";
          } else if (datadelType == "died") {
            j18_backgrounds = "เสียชีวิต";
          } else if (datadelType == "disclaim") {
            j18_backgrounds = "สละสิทธิ์ไม่รายงานตัว";
          }
  
          this.updateJ18s.ApiKey = this.ApiKey;
          this.updateJ18s.id_position = this.editpersonnel_temporary.id_position;
          this.updateJ18s.positions = this.editpersonnel_temporary.position_name;
          this.updateJ18s.j18_background =
            j18_backgrounds +
            ":" +
            this.del_personnel_temporary_del.ptdel_datetime_outof;
  
          let resultinsdel = await this.$http.post(
            "personnel_temporary_del.insert.php",
            this.del_personnel_temporary_del
          );
          
  
          if (resultinsdel.data.status == true) {
            let result = await this.$http.post(
              "personnel_temporary.delete.php",
              this.editpersonnel_temporary
            );
            let resultj18 = await this.$http.post(
              "jor18.updateIdPosition.php",
              this.updateJ18s
            );
  
            
            
            
  
            if (result.data.status == true && resultj18.data.status == true) {
              this.personnel_temporary = result.data;
              Swal.fire({
                icon: "success",
                title: "ดำเนินการลบข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              this.college_search = "";
              this.position_search = "";
              this.rangname_search = "";
              let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
              this.data_syslog.ApiKey = this.ApiKey;
              this.data_syslog.user_account = userSession.user_name;
              this.data_syslog.event_log = "delete";
              this.data_syslog.page_log = "personnel_temporary";
              this.data_syslog.table_log = "personnel_temporary";
              this.data_syslog.detail_log = this.personnel_temporary.id_card;
              this.data_syslog.date_times = this.date_today_log;
              await this.$http.post("data_syslog.insert.php", this.data_syslog);
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการลบผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
            this.deletepersonnel_temporarydialog = false;
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.deletepersonnel_temporarydialog = false;
        }
      },
      async csvpersonnel_temporarySubmit() {
        await this.sweetAlertLoading();
        let result = "";
        let uploaded = null;
        if (this.file2) {
          let formData = new FormData();
          let filename = this.time_stamp + ".personnel.csv";
          formData.append("file", this.file2);
          formData.append("filename", "../HRvecfiles/" + filename);
          formData.append("ApiKey", this.ApiKey);
          result = await this.$http.post("personnel_importcsv.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          uploaded = true;
        } else {
          uploaded = false;
        }
        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
        }
        Swal.close();
        this.csvpersonnel_temporarydialog = false;
      },
  
      async csvpersonnel_temporaryOutofSubmit() {
        await this.sweetAlertLoading();
        let result = "";
        let uploaded = null;
        if (this.file2) {
          let formData = new FormData();
          let filename = this.time_stamp + ".personnelretried.csv";
          formData.append("file", this.file2);
          formData.append("filename", "../HRvecfiles/" + filename);
          formData.append("ApiKey", this.ApiKey);
          result = await this.$http.post(
            "personnel_importretriedcsv.php",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          );
          uploaded = true;
        } else {
          uploaded = false;
        }
        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
        }
        Swal.close();
        this.csvpersonnel_temporaryOutofdialog = false;
      },
  
      async UpdateJ18() {
        await this.sweetAlertLoading();
        let data = {
          ApiKey: this.ApiKey
        };
        let resulta = await this.$http.post("jor18.update.php", data);
        let resultb = await this.$http.post("jor18.update.admin.php", data);
        if (resulta.data.status == true && resultb.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        Swal.close();
      },
      validateInputAdd() {
        const maxLength = 13;
        const regex = /^[0-9]\d*$/;
        let value = this.addpersonnel_temporary.id_card;
        let i, sum;
        if (
          value !== undefined &&
          value.toString().length == maxLength &&
          value.match(regex)
        ) {
          if (value.length != 13) return false;
          for (i = 0, sum = 0; i < 12; i++) {
            sum += parseInt(value.charAt(i)) * (13 - i);
          }
          let mod = sum % 11;
          let check = (11 - mod) % 10;
          if (check == parseInt(value.charAt(12))) {
            this.validateInputcheck = "true";
          }
        } else {
          this.validateInputcheck = "false";
        }
      },
      async validateInput() {
        const maxLength = 13;
        const regex = /^[0-9]\d*$/;
        let value = this.editpersonnel_temporary.id_card;
        let i, sum;
        if (
          value !== undefined &&
          value.toString().length == maxLength &&
          value.match(regex)
        ) {
          if (value.length != 13) return false;
          for (i = 0, sum = 0; i < 12; i++) {
            sum += parseInt(value.charAt(i)) * (13 - i);
          }
          let mod = sum % 11;
          let check = (11 - mod) % 10;
          if (check == parseInt(value.charAt(12))) {
            this.validateInputcheck = "true";
          }
        } else {
          this.validateInputcheck = "false";
        }
      }
    },
    computed: {
      time_stamp() {
        const d = new Date();
        let time = d.getTime();
        return time;
      },
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      p_word() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let password = dd + "/" + mm + "/" + yyyy;
        return password;
      },
      brith_day() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let dd = parseInt(String(today.getDate()).padStart(2, "0"));
        return dd;
      },
      brith_month() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0"));
        return mm;
      },
      brith_year() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let yyyy = today.getFullYear() + 543;
        return yyyy;
      },
  
      
      appoin_day() {
        let today = new Date(
          this.addpersonnel_temporary.appoin_days ||
            this.editpersonnel_temporary.appoin_days
        );
        let dd = parseInt(String(today.getDate()).padStart(2, "0"));
        return dd;
      },
      appoin_month() {
        let today = new Date(
          this.addpersonnel_temporary.appoin_days ||
            this.editpersonnel_temporary.appoin_days
        );
        let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
        return mm;
      },
      appoin_year() {
        let today = new Date(
          this.addpersonnel_temporary.appoin_days ||
            this.editpersonnel_temporary.appoin_days
        );
        let yyyy = today.getFullYear() + 543;
        return yyyy;
      },
      date_today_cal() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();
  
        today = yyyy + "-" + mm + "-" + dd;
        return today;
      },
      retrire_year() {
        let mm = this.brith_month;
        let yy_retire = this.brith_year;
        let result;
        if (mm > 9) {
          result = yy_retire + 61;
        } else {
          result = yy_retire + 60;
        }
        return result;
      },
      cal_date_age() {
        let today = new Date(this.date_today_cal);
        let DOB = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
  
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      },
  
      cal_date_age_gov() {
        let today = new Date(this.date_today_cal);
        let DOB = new Date(
          this.addpersonnel_temporary.date_app_now ||
            this.editpersonnel_temporary.date_app_now
        );
  
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  