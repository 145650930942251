<template>
    <div>
     
    <v-bottom-navigation color="info" horizontal :background-color="colorfrist" dark height="40px">   
      <v-btn to="/admin/personnel_tem" class="mr-5">
        <span>ข้อมูลบุคคล</span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_temporaryLicence" class="mr-5">
        <span>ข้อมูลใบอนุญาต</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_temporary_del" class="mr-5">
        <span>ข้อมูลผู้พ้นจากราชการ สอศ.</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>

      <v-btn to="/admin/jor18" class="mr-5">
        <span>ข้อมูล จ.18</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>   
      </v-bottom-navigation>


      <v-bottom-navigation color="info"  horizontal :background-color="colorsecord" dark height="30px">   
        <v-btn to="/admin/personnel_temporary_assisstant" class="mr-5">
        <span>ครูผู้ช่วย</span>
        <v-icon>mdi-numeric-0-box</v-icon>
      </v-btn>


      <v-btn to="/admin/personnel_tem" class="mr-5">
        <span>วิทยาลัย</span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_center/?type=C" class="mr-5">
        <span>สถาบัน</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>
      <v-btn to="/admin/personnel_center/?type=S" class="mr-5">
        <span>ศึกษานิเทศก์</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_center/?type=38" class="mr-5">
        <span>38 ค.(2)</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>
  
      </v-bottom-navigation>


     
    </div>
</template>

<script>
export default {
    name: 'HRvecPersonnelmenu',

    data() {
        return {
            personnels:false
        };
    },

    mounted() {
        
    },

    methods: {
        async personnelselect(){
            this.personnels=true
        },
    },
    computed:{
        colorfrist() {
            return "#202224";
        },
        colorsecord() {
            return "#004D40";
        },

    }
};
</script>

<style lang="scss" scoped>

</style>